{
 "attachments": "Attachments",
 "dissuaded": "Dissuaded",
 "beta": "Beta",
 "primaryTextNoAuthPage": "Your customer area",
 "secondPrimaryTextNoAuthPage": "A better workflow",
 "paragraphNoAuthPage": "Discover our powerful features that elevate your experience. From intuitive user interfaces to advanced functionalities, our platform is packed with tools designed to streamline your workflow and maximize productivity",
 "ticketingService": "Ticketing Service",
 "ticketingServiceDetail": "Enjoy the support experience with a responsive and knowledgeable team, ready to assist you efficiently in your needs",
 "advancedStatistics": "Advanced statistics",
 "advancedStatisticsDetail": "Data-driven decision making, performance tracking, forecasting and scheduled reporting for optimal 3CX call information",
 "waiting": "waiting",
 "range": "range",
 "internalReplyContent": "Internal reply",
 "columns": "columns",
 "declinedSolicitations": "Declined solicitations",
 "declinedCalls": "Declined calls",
 "noDataFoundTitle": "No Data Found",
 "queue_name": "queue name",
 "element_name": "element name",
 "answered_percentage": "answered %",
 "is_answered": "Calls answered",
 "fromTotalCalls": "on a total of {total} calls",
 "callIdTitle": "Call #{callId}",
 "voicemailExt": "Voicemail",
 "externalNumber": "External number",
 "waiting_duration": "waiting duration",
 "avg_talking_duration": "avg talking duration",
 "avg_waiting_duration": "avg waiting duration",
 "showQueuesOnlyStats": "Show only queue stats",
 "app.state": "State",
 "documentName": "Name",
 "SMSInformation": "SMS Information",
 "setSMSExpeditorName": "Set SMS Expeditor Name",
 "SMSConfiguration": "SMS Configuration",
 "contentCopied": "Content copied to clipboard",
 "CTIConfiguration": "CTI Configuration",
 "ctiDetail": "CTI Detail",
 "newCti": "New CTI",
 "callqueueGroup": "Callqueues group",
 "newDestination": "New destination",
 "agentDetails": "Agent details - {agentNumber} - {agentName} ",
 "showDetailedCallStats": "Show detailed stats",
 "expertStatsDurationsInfoText": "The chart is showing the percentage of calls answered in the selected range time - of each queue - for the selected period.",
 "expertStatsDurationsInfoSubTextOne": "Use the range slider below to select the average waiting time - default value is 20 seconds.",
 "expertStatsDurationsInfoSubTextTwo": "Select the queue in the search box above to see detailed daily average waiting time statistics.",
 "extension": "Extension",
 "ringing_duration": "Ringing duration",
 "talking_duration": "Talking duration",
 "overview": "Overview",
 "outgoing": "Outgoing calls",
 "miss": "Miss",
 "mrs": "Madam",
 "mr": "Mister",
 "mrandmrs": "Madam and Mister",
 "doctor": "Docteur",
 "clickToValidateContact": "Your file contains {element} valid contact(s) and can be imported by clicking on",
 "contacts": "Contacts",
 "contact": "Contact",
 "idCodeFieldRequirement": "Required when no calling number provided.",
 "invoicing": "Invoices",
 "documentFormat": "{type} format",
 "documents": "Documents",
 "services": "Services",
 "customer-files": "Customer files",
 "ticketing": "Tickets",
 "smart-routings": "Smart routings",
 "sms": "SMS services",
 "webradios": "Web radios",
 "pbx-hosts": "PBX hosts",
 "expert-statistics": "Experts statistics",
 "training": "Training",
 "view": "View",
 "customers": "Customers",
 "information-collections": "Informations collection",
 "newUser": "New user",
 "contactDetailView": "Contact detail - {entity}",
 "contactLists": "Contacts list",
 "allPermission": "Full access",
 "client.admin": "Administrator",
 "client.accountant": "Accountant",
 "client.technician": "Technician",
 "createPermission": "Create permission",
 "editPermission": "Edit permission",
 "deletePermission": "Delete permission",
 "viewPermission": "View permission",
 "updatePermission": "Update permission",
 "writePermission": "Write permission",
 "permission.*": "All access",
 "permission.create": "Right to add",
 "permission.edit": "Right to edit",
 "permission.delete": "Right to delete",
 "permission.view": "Right to read",
 "permission.update": "Right to update",
 "permission.write": "Right to write",
 "permission.send": "Right to send",
 "permission.enable": "Right to activate/disactivate",
 "civility": "Civility",
 "hasUser": "An user is linked to this contact",
 "hasNoUser": "Attribution to credential in progress",
 "active": "Active",
 "nonactive": "Non active",
 "noProvided": "{type} not provided",
 "noPermissionsTitle": "User being created... ",
 "noPermissionsDescription": "We are creating an account for this contact, please wait a few seconds...",
 "entityCreatedOn": "This @.lower:contact was created on {date}",
 "waitingTime": "waiting time",
 "timeRange": "Time range",
 "timeGap": "Time gap",
 "columnsData": "Columns data",
 "groupData": "Group data",
 "waitingTimeRange": "Waiting time (5 to 30s):",
 "durationWaitingTime": "Waiting time duration",
 "percentageWaitingTime": "Percentage of calls answered in the selected range time",
 "avgDurationTotal": "Average duration total",
 "avgWaitingTime": "Average waiting time",
 "avgCallDuration": "Average call duration",
 "displayAnswerTimeIn": "Display answer time in",
 "successMsg": "Action successful",
 "webRadios": "Web Radios",
 "webRadio": "web radios",
 "smsSserviceTitle": "SMS Services",
 "smsSserviceSubTitle": "Manage contacts and send transactional or commercial SMS",
 "smsSserviceCartTitle": "Introducing the SMS services",
 "smsSserviceCartDetail": "Improve your business communication with Encom's SMS Service: streamlined messaging, contact management, and analytics in one platform. Send individual or grouped messages",
 "smsSserviceListTitle": "What's included",
 "smsSserviceList1": "Send transactional or promotional SMS messages",
 "smsSserviceList2": "Manage and organize your contacts and lists",
 "smsSserviceList3": "Only pay for what you use",
 "smsSserviceList4": "Schedule campaigns using the API",
 "smsSserviceFooterTitle": "Activate the service",
 "smsSserviceFooterDetail": "Ready to get started ? Contact your sales representative to activate the SMS service on your account",
 "newStream": "New Stream",
 "newRadio": "New Radio",
 "quizNotCompleted": "Quiz not completed",
 "myCourses": "my courses",
 "myResults": "my results",
 "myPrograms": "my programs",
 "completedAt": "Completed at",
 "newNumbers": "New numbers",
 "newNumbersInformationDescription": "Add the recipients numbers of your SMS here, separated by commas",
 "addNewNumbers": "Add new numbers...",
 "addRecipient": "add recipient",
 "phoneContactsImported": "{n} phone contacts imported",
 "tablePreview": "Table preview",
 "onlyFileAccepterd": "Only {file} files accepted",
 "contactTablePreviewConfirmationMsg": "File is ready to upload. Would you like to confirm",
 "contactTablePreviewNoProblemsMsg": "No problems found in the file",
 "contactUploadErrorTitle": "The following errors were found in the uploaded file",
 "contactUploadErrorMsg": "The following errors were found in the uploaded file",
 "contactsErrMsg": "Wrong number format. No letters or symbols allowed. Numbers separated by comma only.",
 "destErrMsg": "Wrong number format. No letters or symbols allowed.",
 "editContact": "edit contact",
 "addDestination": "Add destination",
 "contactForm": "Contact form",
 "contactFormDescription": "Enter new contact information below",
 "importContactMsg": "Your import file must be an Excel format, it can contain, either new entries only, or all the dataset. Existing contacts will be updated with the new information and the new contacts will be created.",
 "deleteItem": "Delete item",
 "confirm": "Confirm",
 "goBack": "Go Back",
 "confirmationMessage": "Are you sure you want to delete item?",
 "confirmButton": "Proceed",
 "svi": "SVI",
 "studio": "Studio Message",
 "network": "Site & Network",
 "relations": "Customer Relations",
 "extensionBeingUsed": "Extension is being used in {forms}. Please remove from the form first.",
 "apiNotFound": "Application not recognized",
 "apiNotFoundErrorMsg": "Unfortunately we are having trouble recognizing this application. Please get in touch so we can solve your issue.",
 "getInTouch": "Get in touch",
 "getInTouchSupportMsg": "Please don't hesitate to contact us",
 "search": "Search",
 "siteNetworkInformations": "Site and network information",
 "siteNetworkInformationsDetail": "This information allows us to integrate VoIP telephony into your IT network. We also remind you of the technical requirements necessary to guarantee voice quality",
 "dialAlreadyUsed": "This dial choice is already being used",
 "descAlreadyUsed": "This destination is already being used",
 "pressDialFor": "Press {dial} for",
 "rioMandatoryWarning": "The RIO is not mandatory here but is strongly recommended as we will ask you for it, in most of the cases",
 "associateNumber": "associated number",
 "adminExt": "admin extension",
 "somethingWentWrong": "Something went wrong",
 "somethingWentWrongWhileImporting": "There was a problem importing the file",
 "nonConfiguredNumbers": "Output of non-configured numbers",
 "nonConfiguredNumbersDetail": "In the rare case that a call does not go through because the number is not configured, choose which extension these calls will be redirected",
 "errorOnFinalFormFqdn": "Please provide your domain",
 "selectAll": "Tout sélectionner",
 "andNumberMoreElements": "and {numb} more elements...",
 "noMoreData": "There is no more items to display 🚀",
 "carriedNumber": "Select one of your carried numbers",
 "participants": "participants",
 "wishToContinue": "Do you wish to continue ?",
 "openingDaysAndTime": "opening days and time",
 "strategy": "strategy",
 "predecroche": "predecroche",
 "predecrocheAgent": "predecroche agent",
 "destNoResp": "destination no response",
 "nonAnsweringDestination": "non answering destination",
 "adminNotifications": "admin notifications",
 "isComplete": "is complete",
 "generalSettings": "General settings",
 "strategyClosingHours": "Strategy for closing hours",
 "advancedSettings": "Advanced settings",
 "callqueues": "Call queues",
 "order": "order",
 "needAtLeastOneContact": "Need at least one contact",
 "needAtLeastOneAddress": "Need at least one address",
 "callQueueOrder": "Call queue order",
 "callQueueOrderDescription": "Use arrows or drag to select preferred elements order in queue",
 "attentionNeeded": "Attention needed",
 "elementDeletedSuccessfully": "{element} deleted successfully",
 "elementCreatedSuccessfully": "{element} created successfully",
 "elementUpdatedSuccessfully": "{element} updated successfully",
 "elementDuplicatedSuccessfully": "{element} duplicated successfully",
 "duplicateList": "Duplicate list",
 "noListAdded": "No list added",
 "georouting": "Geographical routings",
 "districts": "Districts",
 "destinationRoutingListAvailable": "Destination routing list available",
 "geographicalDataModel": "Geographical data model",
 "geographicalRoutesList": "Geographical routes list",
 "geographicalRoutesAvailable": "Geographical routes available",
 "geographicalDestinationRoutes": "Routings list",
 "newGeoRouting": "New routing",
 "geoModel": "Geographical model",
 "routing": "Routing",
 "frenchDepartments": "French departments",
 "idList": "ID list (API)",
 "addGroups": "Add groups",
 "addGroupsDetail": "You haven’t added any extensions group to your collect yet. You can start now and manage their permissions",
 "usersList": "A list of all users in the group, with their name, extension and rights",
 "custom": "custom",
 "noCustom": "no custom",
 "open": "Open",
 "wrongRioFormat": "Wrong RIO format",
 "provideCorrectNumberOrRio": "Please provide correct phone number and/or correct RIO",
 "welcomeMessage": "welcome message",
 "toBeCompleted": "to be completed",
 "completed": "Completed",
 "continue": "Continue",
 "updateCallQueueForm": "Update by complete informations below",
 "callQueueUpdatedSuccessfully": "Callqueue updated successfully",
 "callqueueDetailsSettings": "Callqueue details and settings",
 "destination": "Destination",
 "callQueueGroupUpdatedSuccessfully": "Callqueue group updated successfully",
 "deleteMessageTitle": "Delete item",
 "unlinkCallQueue": "Unlink callqueue",
 "exception": "exception",
 "timeSlotAddedSuccessfully": "Timeslot added successfully",
 "timeSlotUpdatedSuccessfully": "Timeslot updated successfully",
 "timeSlotDeletedSuccessfully": "Timeslot deleted successfully",
 "exceptionAddedSuccessfully": "Exception added successfully",
 "documentImportedSuccessfully": "Document imported successfully",
 "to": "to",
 "noCode": "No code",
 "noNumber": "No number",
 "exceptionUpdatedSuccessfully": "Exception updated successfully",
 "timeSlot": "Timeslot",
 "exceptionDeletedSuccessfully": "Exception deleted successfully",
 "deleteTimeSlot": "Delete time slot for {type}",
 "deleteException": "Delete exception for {type}",
 "createAndUnlink": "Créer et dissocier",
 "unlinkCallQueueTitle": "Are you sure you want to create a {type} for this specific call queue ? By doing this, you will automatically unlink this call queue with the call queue group related to it.",
 "deleteCallQueue": "Delete callqueue {name}",
 "deleteCallQueueGroup": "Delete callqueue group {name}",
 "deleteMessage": "If you delete this item you may lose information from forms already completed. Do you wish to continue ?",
 "entityIncorrectorNotFound": "{entity} incorrect or not found",
 "errorFormNumberInside": "This selection create conflict to an existed one.",
 "errorFormNumberFromSmallerThanTo": "Start of the range can't be smaller than the end.",
 "errorFormNumberFromAndToEqual": "If you want to add a single number, use Isolated Numbers instead.",
 "enableServiceTitle": "The {service} service is not enabled",
 "enableServiceText": "Take advantage of this service by subscribing with your sales contact",
 "inProgress": "In progress",
 "sviSdaLinkedError": "Please provide one or more numbers to associate",
 "contactDevTeam": "Contact developer team",
 "unauthorizedAction": "Unauthorized",
 "notAuthorized": "Sorry, you are not authorized to perform this action.",
 "otherInformation": "Other information",
 "otherInformationDescription": "You can add this recipient to a list",
 "updateUser": "Update user",
 "updateUserDescription": "Enter new informations below",
 "expeditorName": "Expeditor name",
 "expeditorNameDescription": "The name displayed to the recipient when receiving SMS",
 "smsTypeDescription": "Choose the type of SMS",
 "smsMessageDescription": "Type the content of the SMS",
 "recipientInformationNewDescription": "Add the informations of the new recipient here",
 "recipientListInformationNewDescription": "Add the informations of the new list here",
 "disactivate": "disactivate",
 "marketingDisclaimerPart1": "Be careful ! For all marketing SMS, the message must finish by",
 "marketingDisclaimerPart2": " It's mandatory in order to provide your recipients with a means of unsubscribing and to comply with the obligations of the CNIL",
 "recipientInformation": "Recipient Information",
 "recipientInformationDescription": "Add the recipients of your SMS here. You can also combine this with a recipients list",
 "campaignNameDescription": "Type your campaign name",
 "signinToAccount": "Sign in to your account",
 "locked": "Locked",
 "signin": "Log in",
 "show": "Show",
 "showMore": "Show more",
 "showLess": "Show less",
 "forgotPassword": "Forgot password",
 "password": "Password",
 "newPasswordCondition": "Choose a new password :",
 "newPassword": "New password",
 "confirmPassword": "Confirm password",
 "passwordMustMatch": "Both password must match",
 "passwordLength": "The password must have at least 12 characters",
 "allPasswordCondition": "The password must contain one uppercase, one lowercase, one number",
 "newPasswordDescription": "It must contain one uppercase, one lowercase, one number",
 "ownedBy": "Owned by",
 "ownerEmail": "Owner email",
 "ready": "ready",
 "newRecipient": "new recipient",
 "newAccount": "new account",
 "newAccountDescription": "Please create new account by completing form below",
 "newList": "new list",
 "content": "content",
 "cantBeEmpty": "can not be empty",
 "billing": "billing",
 "billingMode": "Billing mode",
 "updatedDate": "Updated",
 "updateOrganisation": "Update organisation",
 "updateCampaign": "Update SMS Campaign",
 "organisationInformation": "Organisation Information",
 "organisationInformationDescription": "Organisation you related and details about it",
 "campaign": "campaign",
 "campaigns": "campaigns",
 "recipient": "recipient",
 "recipients": "Recipients",
 "recipientsLists": "Recipients Lists",
 "list": "list",
 "user": "user",
 "userMessage": "User",
 "accounts": "accounts",
 "goodMorning": "good morning",
 "goodAfternoon": "good afternoon",
 "goodEvening": "good evening",
 "accepted": "accepted",
 "marketing": "marketing",
 "choice": "choice",
 "campaignReady": "Campaign ready",
 "campaignInformation": "Campaign information",
 "campaignDetail": "Campaign detail and statistics",
 "newCampaign": "new campaign",
 "newMessage": "new message",
 "newOrganisation": "New organisation",
 "newOrganisationDescription": "Please create new organisation by completing form below",
 "accountDescription": "Choose the account for this campaign",
 "smsType": "Type of SMS",
 "autoCallback": "Auto callback",
 "autoCallbackDescription": "The callback option allows customers who do not wish to wait to be called back. 3CX will read the customer's number and offer to call them back on that number, or to change the number, or to continue waiting. If they choose to call back, 3CX will read an acceptance message and hang up. As soon as an agent in the queue is available, 3CX automatically calls the available agent to connect with the customer",
 "autoCallbackDescriptionYes": "requires a pre-hook informing the customer that they can request a callback by pressing the 2 key on their keyboard at any time during the wait",
 "autoCallbackDescriptionNo": "option disabled",
 "autoCallbackTypeDetail": "Specify the tempo in seconds. The tempo duration includes the pre-hook, for example if the pre-hook lasts 30 sec and the auto-callback tempo is 45 sec, the customer will be offered the auto-callback only after 15 sec of waiting. You can provide us with a studio message for the auto callback announcement or use the default 3CX message",
 "associatedQueue": "Queue(s) associated with the message",
 "associatedExtension": "Extension(s) associated with the message",
 "associatedNumber": "Number(s) associated with the message",
 "useMessage": "use message",
 "voiceChoice": "Voice choice",
 "musicChoice": "Music choice",
 "typeYourMessage": "Please enter your different messages here",
 "yourMessage": "Your message",
 "phonetic": "Phonetic clarification of words subject to interpretation",
 "routingContacts": "Smart routings contacts",
 "callingNumbers": "Calling numbers",
 "code": "Code",
 "isVip": "VIP",
 "isOnCall": "on-Call",
 "import": "importer",
 "export": "Exporter",
 "importUserWarning": "Importing your user directory involves deleting previously created users, this feature is subject to improvement in the near future",
 "importFile": "Import file",
 "importFileDetail": "You have the possibility to import a file containing your entire user directory",
 "importFileDetail2": "the format of the file to be completed, then imported",
 "download": "Download",
 "downloadInvoice": "Download invoice",
 "downloadConsumption": "Download the consumption statement ({type} format)",
 "here": "here",
 "or": "or",
 "dragAndDrop": "drag and drop",
 "notRecord": "Suggest to the caller not to be registered via key 3",
 "notRecordDescription": "If you record incoming calls, you can suggest to callers to not record the call",
 "notRecordYes": "key 3 is activated. Your pre-hook message should inform the caller that they have the option of not being recorded by dialing key 3 on their phone",
 "notRecordNo": "key 3 is disabled. The pre-hook message should state that the caller only has the option of asking the other party not to record the conversation",
 "uploadedBy": "Uploaded by",
 "uploadedAt": "Uploaded at",
 "uploadAFile": "Upload a file",
 "adminQueueExtension": "Queue administrator(s) extension",
 "userQueueExtension": "Extension number of group members",
 "nonWorkingTimeDestination": "Destination in non-working hours",
 "audioMessageNumber": "Audio message number",
 "messageName": "Message name",
 "strategyType": "Scanning strategy",
 "prehook": "Pre-hook",
 "prehookDescription": "Indicate on the right the number of the pre-hook message (see MESSAGE STUDIO ) or the name of the message provided by you",
 "prehookBeforeCallAgent": "Play pre-hook before ringing agent",
 "allDestinations": "All open destinations",
 "activated": "activated",
 "activateRecording": "All external calls, incoming and outgoing, are recorded",
 "desactivated": "desactivated",
 "ivsDescriptionInfo": "To be put in touch with the sales department, dial 1, to be put in touch with the technical department dial 2, etc.",
 "ivsDescription": "Interactive Voice Server (IVS)  improves the quality and efficiency of your telephone reception. A pre-hook message allows incoming calls to be directed directly to the right person or to a dedicated voice mailbox",
 "ivsList": "IVS List",
 "ivsName": "IVS Name",
 "messageNameInvalid": "This queue name is already being used",
 "desactivateRecording": "No calls are recorded",
 "manual": "Manual",
 "manualRecording": "Call recording is initiated manually",
 "address": "Address",
 "addUser": "Add user",
 "addressDetail": "Add one or more geographic addresses",
 "availableFromVersion": "Available from version",
 "contactDetail": "Add and indicate contacts, including",
 "contactDetailList1": "The technical contact for the deployment of 3CX",
 "contactDetailList2": "The accounting contact for receiving and processing invoices",
 "comment": "Comment",
 "addYourComment": "Add your comment",
 "activityFeed": "Activity feed",
 "ticketOpenedBy": "{id} opened by",
 "openedBy": "opened by",
 "calls": "Calls",
 "members": "Members",
 "strategyOpeningHours": "Strategy opening hours",
 "24hOption": "24h Option",
 "24hOptionDescription": "Using the 24h option, the item will be available for {days} all day.",
 "recording": "Calls recording",
 "recordingDisclaimer": "This service is subject to GDPR compliance",
 "recordingDisclaimerDetail1": "A predisclaimer of the type",
 "recordingDisclaimerDetail2": "is mandatory to enable recordings. To keep recordings for more than 1 month",
 "recordingDisclaimerSentence": "Your conversation may be monitored or recorded",
 "recordingDisclaimerList1": "Please indicate how long you want to store the messages in the comment section",
 "recordingDisclaimerList2": "Subscription to a storage option longer than 1 month is subject to a charge and must be indicated on the order form",
 "newQueue": "New queue",
 "callQueueName": "Callqueue name",
 "callsRestrictions": "Restriction on other destinations activated: indicate in the comment section the other restrictions for each user or user group",
 "noCallsRestrictions": "Restriction on other destinations disabled",
 "signatory": "Signatory",
 "signatoryContact": "Signatory",
 "technical": "Technical",
 "technicalContact": "Technical Contact",
 "accounting": "Accountant",
 "accountingContact": "Accounting Contact",
 "physicalSite": "Physical sites",
 "physicalSiteName": "Physical sites name",
 "intercallDelayDescription": "Pause time between the reception of 2 calls. Useful for taking notes at the end of an interview or closing a CRM form",
 "intercallDelay": "Inter-call delay. (in seconds)",
 "priorityQueue": "Priority queue",
 "priorityQueueDescription": "In case agents belong to several queues, the calls of the priority queue will be distributed in priority to the agents",
 "state": "State / Province",
 "thereWere": "There were",
 "youUploaded": "You've uploaded",
 "youCanAddByClicking": "You can add them by clicking on the button",
 "successfullyUploaded": "Successfully uploaded",
 "noFileHasBeenUploaded": "No file has been uploaded",
 "below": "below",
 "errorLogin": "Invalid e-mail address or password",
 "errors": "errors",
 "oneOrMore": "one or more",
 "none": "none",
 "subject": "Subject",
 "signedInAs": "Signed in as",
 "yourFeedback": "Help us improve your customer area!",
 "sendYourFeedbackDescription": "All your comments and suggestions help us to improve your customer area.",
 "valueOutOfFive": "{numb} out of 5",
 "tooManyElements": "Not available: there are too many elements to select",
 "switchingTimes": "Switching times in seconds",
 "errorWithYourSubmission": "error with your submission",
 "trainings": "Trainings",
 "trainingMandateHeadline": "Training paid by your OPCO",
 "trainingUser": "User Training",
 "trainingAdmin": "Admin Training",
 "miscellaneous": "miscellaneous",
 "webClient": "Web Client",
 "webClientDetail": "Allows the user to connect to the 3CX management interface via a web page",
 "city": "City",
 "waitingQueues": "Waiting Queues",
 "waitingQueuesDescription": "Call Queues allow calls to be put on hold until agents (members of a queue) can answer the calls.",
 "waitingQueuesDescriptionOld": "Call Queues allow calls to be put on hold until agents (members of a queue) can answer the calls. Calls are not left unanswered, they are put on hold. For example, it is possible to set up a group of 3 salespeople, and have the sales department's number routed to a specific queue for the sales department. If all 3 sales representatives are busy, the callers will be queued until a sales representative becomes available. This table allows you to define the operation of one or more call queues",
 "yes": "yes",
 "yesPredecrocheAgent": "The extension will only start ringing when the pre-hook message is completed",
 "noPredecrocheAgent": "The extension will start ringing as soon as the prehook message starts playing",
 "predecrocheMusic": "Music on hold after pre-hook",
 "predecrocheMusicDescription": "Indicate on the right the name of the music on hold after pre-hook",
 "maxCallerNumb": "Maximum number of callers in the queue",
 "openTimeSlot": "Opening days and times",
 "openTimeSlotDetail": "Click to add opening days and hours",
 "no": "no",
 "noResponseTime": "Time until destination if no answer. (in seconds)",
 "edit": "Edit",
 "editItem": "Edit item",
 "editItemMessage": "If you edit this item you may lose information from forms already completed. Do you wish to continue?",
 "editItemMessageInfo": "If you want to keep the information already existent click on cancel.",
 "cancel": "Cancel",
 "save": "Save",
 "add": "add",
 "fullName": "Full name",
 "firstName": "First name",
 "lastName": "Last name",
 "outputDisplayNumber": "Output display number",
 "personalInformation": "Informations about the user",
 "personalInformationDetail": "Global information about the user",
 "extensionMandateDetail": "3 to 5 digit formats (excluding 112 reserved for emergencies)",
 "phoneNumber": "Phone",
 "mobileNumber": "Mobile",
 "mobilePhone": "Mobile number",
 "landlinePhone": "Phone number",
 "phoneOrMobile": "Phone or mobile",
 "beNotified": "I wish to be notified by e-mail",
 "notBeNotified": "I do not wish to be notified by e-mail",
 "notificationMandate": "Decide which notifications the user will receive by email",
 "missedCalls": "Missed calls not notified/notified",
 "missedCallsDetails": "They are sent by email to the above address",
 "voicemail": "Unsent/sent voicemail messages",
 "voicemailDetail": "Email notification with audio file attachment",
 "hotdesking": "Allows the user to connect to a physical station dedicated to the",
 "hotdeskingDetail": "A hot desking station is a station that is not assigned to any fixed user and allows multiple mobile users to connect to it using a pin code",
 "ipPhoneModel": "Associate a telephone set with the user",
 "restrictionsDetail": "Decide which destinations the user can't call",
 "invoices": "Invoices",
 "invoice": "Invoice",
 "unsavedChangesTitle": "Unsaved changes",
 "unsavedChangesMessage": "You have unsaved changes ! If you leave now, unsaved changes will be discarded and your work will be lost.",
 "forbiddenExtension": "You are not allowed to use this extension.",
 "unsavedChangesMessageOption": "Click Cancel to return to the Collection and save your new changes",
 "unsavedChangesCancelButton": "Leave without saving",
 "unsavedChangesOkButton": "Save changes",
 "invoicesDetail": "This contact should receive monthly invoices by email",
 "contactAdminDetail": "This contact is the administrator of the Encom Customer Area",
 "emailErrorFormat": "Please follow format",
 "followFormat": "Please follow format",
 "errorIsInside": "Please choose a number inside a provided range",
 "numbAlreadyAdded": "This number has already been added.",
 "errorCreatedExtensionsLength": "This extension must contain as many digits as previously registered.",
 "theEmail": "The email",
 "internationalCall": "International Calls",
 "internationalCallDetail": "Due to the dematerialized use of the 3CX solution, as opposed to older telecommunications systems, it is important to consider the risk of identity theft through the theft of your 3CX IDs and passwords, allowing a hacker to make calls from your account.",
 "internationalCallDetail2": "Therefore, once the solution has been installed, it is essential to delete the welcome emails received in your inboxes for all affected users. This security measure is designed to prevent unauthorized access attempts.",
 "internationalCallDetail3": "To guarantee optimum security, we also ask you to provide us with a complete list of the countries you call regularly. This list will enable us to restrict the destinations that are immediately accessible. If you need to add other countries in the future, you can open a ticket to request their immediate activation.",
 "internationalCallDetail4": "This precaution is essential to protect you from being overcharged if your login details are stolen. Our priority is to ensure the security of your accounts and the protection of your data.",
 "internationals": "Select permitted destinations",
 "restrictionsInternationals": "Restrictions on international calls",
 "internationalDetail": "Specify destinations to open",
 "restrictionsActivated": "The list of authorised countries is defined in the first part of the Collect",
 "resetCreatePasswordDetail": "If an account exists for this e-mail, a password reset link will be sent to you.",
 "passwordReset": "Reset your password",
 "passwordCreate": "Create your password",
 "passwordResetMustContain12Characters": "MUST contain at least 12 characters",
 "passwordResetMustContainOneUppercase": "MUST contain at least one uppercase letter",
 "passwordResetMustContainOneSpecialCharacter": "MUST contain at least one special character",
 "passwordResetMustContainOneNumber": "MUST contain at least one number",
 "bannerSandbox": "sandbox",
 "bannerProduction": "production",
 "callQueueNumber": "callqueue number",
 "callQueue": "Call queue",
 "queue": "Call Queue",
 "callFlow": "Call flow",
 "all": "All",
 "world": "World",
 "deleteContact": "Delete user {name}",
 "deleteContactMessage": "Are you sure you with to delete this user ?",
 "delete": "Delete",
 "deleteUser": "Delete user",
 "deleteSvi": "Delete SVI",
 "deleteAll": "Delete all",
 "deleteAllContacts": "Delete all contacts",
 "deleteAllContactsDetail": "Attention, you are about to delete ALL the saved contacts. Would you like to continue?",
 "deleteAllCTIDetail": "Attention, you are about to delete ALL the saved CTI configurations. Would you like to continue?",
 "deleteCTIMessage": "Are you sure you want to delete this CTI ?",
 "deleteCTI": "Delete CTI {name}",
 "name": "Name",
 "listsName": "List name",
 "reportList": "Report list",
 "upload": "upload",
 "mainInfos": "Main Infos",
 "numbersPortability": "Numbers & portability",
 "numbersPortabilityTitle": "Numbers portability",
 "numbersPortabilityDetail": "Number portability allows you to keep your current numbers. Please indicate the numbers you wish to carry",
 "rangeNumber": "Range of numbers to carry",
 "start": "start",
 "end": "end",
 "numberIsolated": "Isolated Numbers (Analog Line, Fax, VoIP Number)",
 "sda": "Number",
 "sdaCreation": "Create numbers",
 "sdaCreationDetail": "If you wish to create new numbers, please indicate the number of numbers to be created and the city where they will be used",
 "sdaCreate": "Number of numbers to be created",
 "sdaCreateDetail": "per 5 numbers minimum",
 "sdaLinked": "Number(s) linked",
 "numberToAssign": "Number(s) to assign to the user",
 "internalNumber": "Extension to be associated to the user",
 "sdaOrderedLeft": "Numbers ordered left",
 "sdaOrderedLeftDetail": "Enter your number or order a new one",
 "newNumber": "Create a new number",
 "ownNumber": "Add an own number",
 "portabilityMandate": "Portability mandate",
 "portabilityMandateDescription": "(1) Download here the portability mandate to be completed (2) Check and sign your mandate (3) Import your mandate",
 "portabilityMandateAndInvoice": "Portability mandate(s) and invoice(s)",
 "portabilityMandateDetail": "The mandate must have the same company name and address as the invoice of your previous provider",
 "telephoneOperatorInvoice": "Telephone operator invoice",
 "telephoneOperatorInvoiceDescription": "Please import a copy of your operator invoices (less than 3 months old) showing the numbers to be ported and the header numbers",
 "telephoneOperatorInvoiceDescription2": "If you have added non-bundled numbers, please present an invoice proving that you own them",
 "users": "users",
 "usersTitle": "Utilisateurs",
 "usersCollectDetail": "This section allows you to define the extensions and rights associated with each user",
 "usersMandateHeadline": "The extensions 12, 15, 17, 18 and 112 are reserved for emergency numbers",
 "siteAndNetwork": "Site & Network",
 "customerRelations": "Customer relations",
 "customerName": "Customer name",
 "customerAccount": "Customer Account",
 "endCollect": "End",
 "notBundledNumber": "Not bundled number",
 "othersNumbersPortabilityList": "Not bundled numbers list",
 "othersNumbersPortabilityTitle": "Other not bundled numbers",
 "othersNumbersPortabilityDetail": "If you have numbers that you do not want to carry, but want to use as a featured number for one or more extensions, you can fill them in here",
 "confirmCollect": "Confirm Collect",
 "lostChanges": "Changes are lost",
 "extensionAlreadyUsed": "This extension is already used.",
 "existingExtension": "Existing extension",
 "informationSaved": "Informations saved",
 "legalNotice": "Legal Notice",
 "legalNoticeDetail": "Thank you for taking the time to complete this document",
 "privacyGdpr": "Confidentialité et RGPD",
 "privacyGdprDetail": "It is mandatory to validate the knowledge of the regulations implemented by the RGPD",
 "privacyGdprToogleLegend": "By checking below, you confirm that you are aware of the GDPR regulation and what it entails",
 "privacyGdprComment": "For any additional information on the configuration you want, you can fill in this field",
 "goBackToDependentStep": "Please complete this step first",
 "globalSettings": "Extension Settings",
 "globalSettingsDetail": "General information about your 3CX",
 "selectElementFromList": "Please select one element from the list",
 "userUploadedSuccessfully": "Users uploaded successfully",
 "groupNameAlreadyUsed": "This group name is already being used.",
 "companyName": "Company",
 "3cxDomain": "3CX Domain",
 "yourDomain": "Your Domain",
 "3cxDomainType": "URL to connect to your 3CX domain",
 "3cxDomainRoot": "3CX Root domain",
 "opco": "Your OPCO",
 "street": "Street number and name",
 "postal": "ZIP / Postal",
 "country": "Country",
 "pleaseCorrect": "Please correct",
 "numberOfCalls": "calls",
 "numberOfAnsweredCalls": "answered",
 "callsAnsweredByAnother": "answered by another",
 "callsTransferredByAgent": "transferred by agent",
 "abandonedCalls": "abandoned",
 "callsDeterred": "deterred",
 "averageWaitingTime": "average waiting time",
 "solicited": "solicited",
 "averageWaitingTimeQueue": "average waiting time in queue",
 "dropoutTime": "average dropout time",
 "averageCallDuration": "average call duration",
 "queueDetail": "queue detail",
 "details": "details",
 "number": "number",
 "numbers": "numbers",
 "didCallers": "DID Callers",
 "didReport": "DID Report",
 "pleaseSelectANumberFromTheSelectorAbove": "Please select a number from the selector above",
 "groups": "groups",
 "group": "group",
 "deleteGroup": "Delete group",
 "deleteStudioMessage": "Delete studio message",
 "callQueueDeletedSuccessfully": "CallQueue deleted successfully",
 "callQueueGroupDeletedSuccessfully": "CallQueue deleted successfully",
 "groupDeletedSuccessfully": "Group deleted successfully",
 "userDeletedSuccessfully": "User deleted successfully",
 "sviDeletedSuccessfully": "SVI deleted successfully",
 "studioMessageDeletedSuccessfully": "Studio message deleted successfully",
 "groupsQueues": "groups queues",
 "groupsExtensions": "groups extensions",
 "queueComparison": "Queue comparison",
 "numberOfRepeatedCalls": "repeated calls",
 "answeredCallsCoefficient": "answered calls coefficient",
 "abandonedCallsCoefficient": "abandoned calls coefficient",
 "dissuadedCallsCoefficient": "deterred calls coefficient",
 "comparisonSamePeriodLastMonth": "Comparison same period last",
 "callHistory": "Call history",
 "summaryData": "Summary datas",
 "noCallersForThisPeriod": "No callers found for this period",
 "noCallersForThisPeriodDetail": "Get started by choosing a date, them a phone number in the list, to the left.",
 "pleaseSelectTwoQueuesForComparison": "Please select two queues for comparison",
 "queueHasNoData": "Selected queue has no data for this period",
 "validate": "validate",
 "nextStep": "Save and proceed",
 "selectCategory": "Select category",
 "comparison": "comparison",
 "compareTo": "to",
 "answered": "Answered | Answered below {seconds} seconds",
 "talking": "Talking | Talking below {seconds} seconds",
 "answeredOrUnanswered_seconds": "{key} under {seconds} seconds",
 "averageAnswered_00_30": "calls answered in up to 30 seconds",
 "averageAnswered_31_60": "calls answered between 31 and 60 seconds",
 "averageAnswered_61_90": "calls answered between 61 and 90 seconds",
 "averageAnswered_90+": "calls answered in more than 90 seconds",
 "year": "year",
 "month": "month",
 "bulkYear": "{count} year | {count} years",
 "bulkMonth": "{count} month | {count} months",
 "bulkWeek": "{count} week | {count} weeks",
 "bulkDay": "{count} day | {count} days",
 "bulkHour": "{count} hour | {count} hours",
 "bulkMinute": "{count} minute | {count} minutes",
 "bulkSecond": "{count} second | {count} seconds",
 "schedule": "schedule",
 "scheduleReportEditDescription": "Do you want to setup a schedule share report ?",
 "createdAt": "created at",
 "created_at": "Created at",
 "updatedAt": "updated at",
 "updated_at": "Updated at",
 "mandateIdentification": "Mandate Identification",
 "at": "at",
 "reportDetailTitle": "Report Information",
 "reportEditTitle": "General Information",
 "reportEditTitleDescription": "General configuration of the rapport",
 "reportDetailTitleDescription": "Global detail and parameters of the report",
 "pbxHost": "PBX host",
 "emailAddress": "Email address",
 "emailAddressReportEditDescription": "Manage people who receive the report",
 "preview": "preview",
 "previewReport": "You can preview the presentation by clicking to the button below",
 "monthly": "Monthly",
 "daily": "Daily",
 "cronDaily": "The report will be shared every day, at 04:00",
 "cronWeekly": "The report will be shared every Monday, at 04:00",
 "cronMonthly": "The report will be shared every 1st of the month, at 04:00",
 "weekly": "Weekly",
 "close": "close",
 "noReportTitle": "No report found",
 "noReportDescription": "Get started by creating a new statistics dashboards",
 "noHostName": "Account has no hosts",
 "closed": "Closed",
 "ticketsClosed": "Tickets fermés",
 "ticketsOpen": "Tickets ouverts",
 "collectionsList": "Collections list",
 "onlineCollect": "online collects",
 "collectCompleted": "Collect completed",
 "collectCompletedAt": "Collect completed at {numb}%",
 "accessToCollect": "Access to the collect {name}",
 "filesList": "Files list",
 "files": "Files",
 "goToSee": "See",
 "hideAnnouncement": "Hide the announcement",
 "file": "File",
 "fileName": "File name",
 "documentNotFound": "Document not found",
 "fileInformation": "File Information",
 "fileInformationDetail": "Details of the customer file and collections",
 "account": "Account",
 "endCallAfterMessage": "End the call",
 "endCallAfterMessageNo": "After the message is played, the user will have the choices defined below",
 "endCallAfterMessageYes": "The call ends after the audio message is played",
 "newCollection": "New collection",
 "newSitePlaceholder": "Call queue extension number, user, IVR...",
 "studioMessage": "Messages & Studio",
 "studioMessageExample": "Examples of pre-pickup messages",
 "studioMessageCreateNew": "Would you like to add a new message?",
 "CRMIntegrationQuestion": "Would you like to integrate your CRM/ERP?",
 "CustomerRelationModules": "Customer relation modules",
 "SmartRouting": "Smart routing",
 "SmartRoutingDetail": "By recognizing the caller's number, smart routing allows each call to be automatically and immediately routed to the right caller",
 "CustomerSatisfacionSurvey": "Customer satisfaction survey",
 "VoiceRecognition": "Voice recognition",
 "LiveWebChat": "Live web chat",
 "WebCallBack": "Web call back",
 "PushSMS": "Push SMS",
 "setActiveAccount": "You are able to switch between accounts by selected one account of the list below",
 "userProfile": "Profile",
 "selectModules": "Select modules to be implemented",
 "CustomerRelationFormInfo": "With 3CX you can benefit from CRM, ERP or accounting system integration with your PBX to be able to make calls to your contacts with a single click from your applications. In addition, incoming calls are automatically linked to a customer account that opens on your screen and all calls are logged in your CRM.",
 "warningTitle": "Warning",
 "login": {
  "welcome": "Welcome",
  "backToLogin": "Back to login page",
  "newPassword": "First connection",
  "forgotPassword": "Forgot password",
  "emailAddress": "Email address",
  "password": "Password",
  "signin": "Sign in"
 },
 "expertStatisticsNavigation": {
  "advancedStatistics": "Advanced Statistics",
  "origins": "Origins",
  "originsTop10": "Origins Top 10",
  "hourlyStatistics": "Hourly Statistics",
  "groups": "Groups",
  "answeredUnansweredCalls": "Answered & Unanswered",
  "answeredUnansweredCallsDaily": "Answered & Unanswered (daily)",
  "reportList": "Report List",
  "answeredUnansweredCallsWeekDay": "-- Vue par jour de semaine",
  "answeredUnansweredCallsWeekYear": "-- Vue par semaine"
 },
 "pageNotFound": "Sorry, we couldn't find the page you're looking for...",
 "goBackHome": "Go back home",
 "sidebar": {
  "statistics": "Statistics",
  "invoices": "Invoices",
  "tickets": { "tickets": "Tickets", "open": "Open", "closed": "Closed" },
  "folder": "Files",
  "collect": "Collects",
  "settings": "Settings",
  "subMenu": { "financial": "Financial", "callQueue": "Call Queues" },
  "specialNavigation": {
   "smartRoutings": "Smart Routings",
   "expertService3CX": "Expert Service 3CX",
   "contact": "Contacts",
   "georouting": "Geographical Routing",
   "callqueuegroup": "Call Queues Groups",
   "callqueue": "Call Queues",
   "reportList": "Report List"
  }
 },
 "header": {
  "search": "Search",
  "searchTicketPlaceHolder": "Subject, created date or updated date",
  "searchInvoicePlaceHolder": "Invoice, date or total",
  "searchContactPlaceHolder": "Company, name, code or numbers",
  "hello": "Hello",
  "activeAccount": "Active Account",
  "accountName": "Account name",
  "account": "Account",
  "invoices": "Invoices",
  "openTicket": "New Ticket",
  "yourProfile": "Your Profile",
  "settings": "Settings",
  "logout": "Sign out",
  "yourFeedback": "Give your feedback",
  "feedback": "Feedback",
  "filter": "Filters",
  "impersonate": "Impersonate",
  "leaveImpersonate": "Leave Impersonate"
 },
 "notificationBanner": {
  "title": "Your application has new features! Open to see more.",
  "learnMoreButton": "Learn more"
 },
 "impersonate": { "popupTitle": "Enter User email to Impersonate" },
 "chartTable": {
  "callType": "Call Type",
  "count": "Count",
  "duration": "Duration",
  "avgPerCall": "Avg per Call",
  "landline": "Landline",
  "mobile": "Mobile",
  "special": "Special",
  "callCount": "Call count",
  "callDuration": "Call duration",
  "fileNumb": "File number",
  "period": "Period",
  "choosePeriod": "Choose period",
  "chooseResource": "Choose resource",
  "resourceNumb": "Resource number",
  "chart": "Charts",
  "data": {
   "incoming": "Incoming",
   "outgoing": "Outgoing",
   "answered": "Answered",
   "unanswered": "Unanswered"
  }
 },
 "dashboards": {
  "title": "Tableau de bord",
  "day": "day",
  "fullDay": "Full day",
  "week": "week",
  "month": "month",
  "concatenate": "Consolidate",
  "concatenateTitle": "Consolidated data for elements:",
  "chartHeaderTitle": "Statistics for period",
  "searchExtension": "Search extensions",
  "searchQueue": "Search queue",
  "custom": "custom",
  "monthly": "Monthly",
  "daily": "Daily",
  "weekly": "Weekly",
  "hourly": "Hourly",
  "monthlyTitle": "Monthly Dashboard of",
  "monthlyDescription": "Get monthly data of",
  "dailyTitle": "Daily Dashboard of",
  "dailyDescription": "Get daily data of",
  "hourlyTitle": "Hourly Dashboard of",
  "hourlyDescription": "Get hourly data of",
  "donutTitle": "Daily Answered & Unanswered Calls Count",
  "stackedColumnCategories": {
   "incomingCount": "Incoming Count",
   "outgoingCount": "Outgoing Count",
   "answeredCount": "Answered Count",
   "unansweredCount": "Unanswered Count",
   "incomingDuration": "Incoming Duration",
   "outgoingDuration": "Outgoing Duration",
   "answeredDuration": "Answered Duration"
  },
  "error": {
   "noDataFoundTitle": "No Data Found",
   "noDataFoundDescription": "The data for this period was not found or don't exist yet, we're sorry for the inconvenience."
  },
  "selectAll": "Select all",
  "clearAll": "Unselect all",
  "clear": "clear"
 },
 "warning": {
  "noDataFoundTitle": "No Data Found",
  "noResultFoundTitle": "No results found",
  "noResultFoundDescription": "Try adjusting your result of filtering otherwise.",
  "noDataFoundDocumentTitle": "No Documents",
  "noDataFoundFilesTitle": "No Files",
  "noDataFoundDocumentDescription": "You don't have any documents. Contact your line manager if it's not supposed to happen.",
  "noDataFoundFilesDescription": "You don't have any files. Contact your line manager if it's not supposed to happen.",
  "noDataFoundContactTitle": "No Contacts",
  "noDataFoundContactDescription": "Get started by creating a new contact or by uploading a contacts list.",
  "noDataFoundCtiTitle": "No CTI",
  "noDataFoundCtiDescription": "Get started by creating a new CTI configuration.",
  "noDataFoundRoutingTitle": "No Geographic Routing",
  "noDataFoundRoutingDescription": "Get started by creating a new geographic routing.",
  "noDataFoundCallQueueTitle": "No Callqueues",
  "noDataFoundCallQueueDescription": "Get started by creating a new callqueue.",
  "noDataFoundCallQueueGroupTitle": "No Callqueue Groups",
  "noDataFoundCallQueueGroupDescription": "Get started by creating a new callqueue group.",
  "noDataFoundTimeSlotDescription": "No time slot created yet. Please add one by clicking on 'Add New Time Slot' button.",
  "noDataFoundExceptionDescription": "No time slot created yet. Please add one by clicking on one of the 'Add Exception' buttons.",
  "noDataFoundSMSListTitle": "No SMS Lists",
  "noDataFoundSMSListDescription": "Get started by creating a new SMS list.",
  "noDataFoundElementGroup": "Get started by creating a new group.",
  "noDataFoundDashboardTitle": "No Dashboard",
  "noDataFoundDashboardDescription": "Get started by selected elements and period.",
  "mandate": "If your number is linked to an internet subscription, number portability will automatically terminate this internet subscription and the associated analog support line. It is therefore important to check that no service depends on this analog line: payment terminal, alarm, fax, etc"
 },
 "pbxExtension": {
  "selectData": "Select Data",
  "selectPeriod": "Select Period"
 },
 "home": {
  "companies": "Companies"
 },
 "ticket": {
  "ticketForm": "Ticket Form",
  "typeDown": "Type down your questions/problems below",
  "category": "Category",
  "subCategory": "Sub Category",
  "selectProblem": "Select Problem",
  "subject": "Subject",
  "status": "status",
  "resolution": "Estimate resolution",
  "priority": "priority",
  "emailSubject": "Ticket subject",
  "body": "Body",
  "typeAbove": "Type the problem above",
  "send": "Send",
  "cancel": "Cancel",
  "reply": "Reply",
  "writeReply": "Write your reply for the ticket",
  "concerns": "Concerns"
 },
 "invoiceTable": {
  "invoiceId": "Invoice ID",
  "invoiceNumb": "Invoice Number",
  "totalWithoutTax": "Total Without Tax",
  "totalWithTax": "Total With Tax",
  "date": "Date",
  "searchId": "Search ID",
  "searchNumb": "Search number",
  "searchMonth": "MM",
  "searchYear": "YYYY",
  "searchAmount": "Search amount"
 },
 "ticketTable": {
  "subject": "Ticket",
  "status": "Status",
  "priority": "priority",
  "createdDate": "Date",
  "createdAt": "Created at",
  "updatedDate": "Updated",
  "actions": "Actions",
  "reopenTicket": "Reopen",
  "closeTicket": "Close",
  "selectRows": "Select rows",
  "showAll": "Show all",
  "previous": "Previous",
  "next": "Next",
  "rows": "rows",
  "confirmationCloseMsg": "Are you sure you want to close the ticket?",
  "confirmationOpenMsg": "Are you sure you want to reopen the ticket?",
  "closeMsg": "Ticket closed successfully",
  "openMsg": "Ticket opened successfully",
  "successMsg": "Action successful",
  "dialogTitleClose": "Close ticket",
  "dialogTitleOpen": "Open ticket",
  "showing": "Showing",
  "to": "to",
  "of": "of",
  "results": "results",
  "searchStatus": "Search status",
  "searchDate": "DD/MM/YYYY",
  "ticketList": "Tickets List"
 },
 "documentTable": {
  "allDocuments": "All documents",
  "yourDocuments": "Your documents"
 },
 "callqueue": {
  "buttonTitle": "new call queue",
  "buttonTitleGroup": "new call queue group",
  "newFormTitle": "Call queue form",
  "newFormTitleGroup": "Call queue group form",
  "updateException": "Update exception",
  "updateTimeSlot": "Update call queue group",
  "updateFormTitle": "Update call queue",
  "updateFormTitleGroup": "Update call queue group",
  "formSubTitle": "Add new call queue information below.",
  "formSubTitleGroup": "Add new call queue group information below.",
  "subTitle": "Update by completed informations below"
 },
 "callqueueTable": {
  "name": "Name",
  "number": "Number",
  "weekdayAndDate": "week days or date",
  "weekday": "Week days",
  "specificDate": "Specific date",
  "timeslot": "time slot",
  "exception": "exceptions",
  "reference": "reference",
  "addTimeSlot": "add time slot",
  "addException": "add exception",
  "addExceptionTwelveMonths": "Add bank holidays for 12 next months",
  "formTimeSlotDescription": "Please select a day or day range and the time slots associated",
  "formExceptionDescription": "Please add a callqueue exception by selecting a specific day",
  "formExceptionTwelveMonthsDescription": "Here the next twelve bank holidays for France",
  "selectAllDays": "All week days",
  "selectAllWorkingDays": "All Working days",
  "inputSelectDays": "Select mutiple days by using",
  "or": "or",
  "day": "day",
  "dateSelected": "Date Selected",
  "daySelected": "Day Selected",
  "start": "start",
  "end": "end",
  "checkStart": "The start can't be between an existed time slot",
  "checkEnd": "The end can't be between an existed time slot",
  "checkBetween": "This timeslot make conflit with existed one",
  "pickedStartBigger": "The start can't be after or the same than the end",
  "newExceptionAdded": "New exception date picked",
  "newExceptionError": "Sorry, there is an error with your selected date",
  "newExceptionExist": "Exception date already exist",
  "selectExceptionError": "Select an exception date in the calendar before to continue",
  "destinationDescription": "You can specify a destination here. The API returns information about the opening or closing of the call queue according to the schedule. Do not specify a destination here if your call flow calls the API later to route the call (geographic or intelligent routing...)",
  "nextFrenchHolidays": "French Bank Holidays over 12 Months",
  "addFrenchHolidaysWarning": "You will automatically add exceptions for all the dates specified above",
  "continue": "Do you wish to continue ?"
 },
 "weekdays": {
  "mon": "monday",
  "tue": "tuesday",
  "wed": "wednesday",
  "thu": "thurday",
  "fri": "friday",
  "sat": "saturday",
  "sun": "sunday"
 },
 "dialog": {
  "confirm": "Confirm",
  "goBack": "Go Back"
 },
 "profile": {
  "userInfo": "User Information",
  "personalDetails": "Personal details",
  "fullName": "Full name",
  "apiToken": "Api Token",
  "email": "Email address",
  "activeAccount": "Active Account",
  "accounts": "Accounts"
 },
 "contactsForm": {
  "searchAccount": "Search account",
  "searchCompany": "Search company",
  "searchName": "Search name",
  "code": "Code",
  "searchNumber": "Search number",
  "contactAdded": "Contact added successfully",
  "contactUpdated": "Contact updated successfully",
  "contactDeleted": "Contact deleted successfully",
  "addContact": "new contact",
  "uploadContact": "upload contacts",
  "companyName": "Company",
  "identificationCode": "Client code identification",
  "firstName": "First name",
  "lastName": "Last name",
  "vip": "VIP",
  "onCall": "On-call contact"
 },
 "pbx": {
  "newGroup": "New group",
  "extensionNewGroupForm": "Extension group form",
  "queueNewGroupForm": "Queue group form",
  "extensionFormSubtittle": "Add new extension group information below",
  "queueFormSubtittle": "Add new queue group information below",
  "more": "more elements...",
  "and": "and"
 },
 "pbxReport": {
  "shareTitle": "Share a report",
  "shareMessage": "You want to share the report with the following settings",
  "shareScheduleTitle": "Share a scheduled report",
  "shareScheduleMessage": "You want to share a scheduled report with the following settings",
  "content": "Please add the recipients to complete the action. If no email is provided, the report will be send to you.",
  "inputError": "Provide a valid email address or one not listed below.",
  "emailRegexError": "Provide a valid email",
  "emailAlreadyExistError": "Provide an email address that has not already been selected",
  "nicknamePlaceholder": "Write report name here",
  "successMsg": "Report sent successfully"
 }
}
